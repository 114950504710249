import React from 'react';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import logo from '../../content/assets/logo.svg';

class Layout extends React.Component {
  render() {
    const { children } = this.props;

    return (
      <div
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          padding: `1em 3em 1em 3em`,
        }}
      >
        <Helmet>
          <link
            href="https://api.tiles.mapbox.com/mapbox-gl-js/v1.0.0/mapbox-gl.css"
            rel="stylesheet"
          />
          <link
            href="https://fonts.googleapis.com/css?family=Lato:300&display=swap"
            rel="stylesheet"
          />
        </Helmet>
        <header className="header-group">
          <div className="header-item-1">
            <Link to="/">
              <img src={logo} className="logo-small" alt="logo" />
            </Link>
          </div>
          <div className="header-item-2">
            <Link to="/help">Help</Link>
          </div>
        </header>
        <main>{children}</main>
        <footer>TopoRace.com © {new Date().getFullYear()}</footer>
      </div>
    );
  }
}

export default Layout;
